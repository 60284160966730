import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'

import media from 'styles/media'
import { Link } from 'gatsby'

const CookiesWrapper = styled.aside`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(248, 247, 245, 0.1);
  opacity: 1;
  transition: 0.3s ease;
  z-index: 1000000;
  height: 248px;
  backdrop-filter: blur(20px);

  ${media.md.min} {
    height: 148px;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  padding: 0 33px;

  ${media.sm.min} {
    width: 100%;
  }
`

const CookiesText = styled.p`
  line-height: 1.5;

  color: ${({ theme }) => theme.colors.black};
`

const StyledCookiesButton = styled.button`
  background-color: ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 20px;
  line-height: 1.2;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.colors.orange}`};
  transition: 0.3s;
  cursor: pointer;
  width: 143px;
  margin-top: 20px;
  box-sizing: border-box;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  ${media.lg.min} {
    max-width: 200px;
  }
`

const StyledLink = styled(Link)`
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`

const COOKIE_KEY = 'aurec-active-cookies-consent'

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set(COOKIE_KEY, 'true')
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)

    localStorage.setItem(COOKIE_KEY, 'true')
  }

  useEffect(() => {
    const value = localStorage.getItem(COOKIE_KEY)
    if (value) {
      setVisible(false)
      setTimeout(() => setRemoveAlert(true), 500)
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <CookiesContainer>
            <CookiesText>
              Strona korzysta z plików cookies w celu realizacji usług. Możesz
              określić warunki przechowywania lub dostępu do plików cookies w
              Twojej przeglądarce.
              <StyledLink to="/polityka-cookies/"> Czytaj więcej</StyledLink>
            </CookiesText>

            <StyledCookiesButton onClick={acceptCookies}>
              OK
            </StyledCookiesButton>
          </CookiesContainer>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
