import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import media from 'styles/media'

import aurecHomeLogo from 'assets/icons/aurec-home-logo.svg'
import aurecActiveLogo from 'assets/icons/aurec-active-logo.svg'
import facebookIcon from 'assets/icons/facebook.svg'
import instagramIcon from 'assets/icons/instagram.svg'
import footerImage from 'assets/images/footer-image.jpg'
import indigitalIcon from 'assets/icons/indigital-logo.svg'
import { StaticImage } from 'gatsby-plugin-image'

const StyledFooterWrapper = styled.footer`
  /* background-image: url('${footerImage}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 47%; */
  line-height: 1.5;
  text-align: center;
  position: relative;
  padding: 130px 0 40px 0;

  ${media.lg.min} {
    background-position-y: 0;
    padding-top: 120px;
    text-align: left;
  }
`

const StyledFooterContentWrapper = styled.div`
  ${media.lg.min} {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
`

const StyledLogosWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 64px;

  ${media.md.min} {
    margin-bottom: 130px;
  }
`

const StyledLogo = styled.img`
  width: 96px;
  margin: 0 30px;
`

const StyledInfoRow = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    margin-bottom: 170px;
    gap: 100px;
  }
`
const StyledAddressWrapper = styled.div``

const StyledAddressName = styled.p`
  font-weight: bold;
  margin-bottom: 22px;
`
const StyledAddressLocation = styled.p`
  margin-bottom: 20px;
`

const StyledContactLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  ${media.lg.max} {
    margin-top: 20px;
  }
`

const StyledLinksWrapper = styled.div`
  margin: 70px 0;

  ${media.lg.min} {
    margin: 0;
    width: fit-content;
    margin-left: auto;
  }
`

const StyledLink = styled(Link)`
  margin-bottom: 27px;
  display: block;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
`

const StyledSocialAndPolicyLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;

  ${media.lg.min} {
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 60px;
  }
`

const StyledSocialLinksWrapper = styled.div`
  display: flex;

  ${media.lg.min} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledSocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;

  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`

const StyledPolicyLinks = styled.div`
  margin-bottom: 50px;

  a {
    margin-bottom: 10px;
  }

  ${media.lg.min} {
    order: 1;
    display: flex;
    gap: 40px;
    margin-bottom: 0;

    a {
      margin-bottom: 0;
    }
  }
`

const StyledBottomFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0;
  /* padding: 20px 5px; */
  position: absolute;
  width: 100%;
  bottom: 0;
`

const StyledBottomFooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;
  flex-direction: column;

  ${media.md.min} {
    flex-direction: row;
  }

  ${media.lg.min} {
    padding: 20px 50px;
    max-width: 1400px;
    margin: 0 auto;
  }
`

const StyledCopyright = styled.p`
  font-size: 11px;

  ${media.md.min} {
    font-size: 14px;
  }
`

const Credits = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 12px;
  ${media.md.min} {
    margin: 0;

    &:hover {
      span {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
      }
    }
    span {
      display: block;
      padding: 4px 6px;
      white-space: nowrap;
      position: absolute;
      right: 0;
      font-weight: 500;
      top: -40px;
      z-index: 1;
      transition: 0.3s;
      background: #fff;
      font-weight: normal;
      opacity: 0;
      transform: scale(0.7) translateY(20px);
      visibility: hidden;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.colors.orange};
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
      font-size: 14px;
    }
  }
`

const CreditsLink = styled.a`
  display: block;
  width: 100px;
  height: 25px;
  margin-left: 10px;
`

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <StaticImage
        src="../../assets/images/footer-image.jpg"
        alt="tło w stopce strony"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          zIndex: -1,
        }}
      />
      <StyledFooterContentWrapper>
        <StyledLogosWrapper>
          <StyledLogo src={aurecHomeLogo} alt="" />
          <StyledLogo src={aurecActiveLogo} alt="" />
        </StyledLogosWrapper>
        <StyledInfoRow>
          <StyledAddressWrapper>
            <StyledAddressName>Aurec Home Holding Sp. z o.o.</StyledAddressName>
            <StyledAddressLocation>
              ul. Jutrzenki 111A,
              <br /> 02-231 Warszawa
            </StyledAddressLocation>
          </StyledAddressWrapper>
          <StyledAddressWrapper>
            <StyledAddressName>Odwiedź nas:</StyledAddressName>
            <StyledAddressLocation>
              ul. Jutrzenki 111A <br /> 02-231 Warszawa
            </StyledAddressLocation>
            <StyledContactLink href="tel:+48882558111">
              tel: +48 882 558 111
            </StyledContactLink>
            <StyledContactLink href="mailto:office@aurechome.pl">
              e-mail: office@aurechome.pl
            </StyledContactLink>
          </StyledAddressWrapper>

          <StyledLinksWrapper>
            <StyledLink to="/czym-jest-aurec/">
              Czym jest Aurec Active?
            </StyledLink>
            <StyledLink to="/jak-korzystac/">Co możesz robić?</StyledLink>
            {/* <StyledLink to="/co-nowego/">Co nowego?</StyledLink> */}
            <StyledLink to="/kim-jestesmy/">Kim jesteśmy?</StyledLink>
          </StyledLinksWrapper>
        </StyledInfoRow>

        <StyledSocialAndPolicyLinksWrapper>
          <StyledPolicyLinks>
            <StyledLink to="/polityka-prywatnosci/">
              Polityka prywatności
            </StyledLink>
            <StyledLink to="/polityka-cookies/">Polityka cookies</StyledLink>
          </StyledPolicyLinks>
          <StyledSocialLinksWrapper>
            <StyledSocialLink
              href="https://www.facebook.com/AurecHome"
              aria-label="facebook link"
              target="_blank"
            >
              <img src={facebookIcon} alt="facebook logo" />
            </StyledSocialLink>
            {/* <StyledSocialLink href="" aria-label="instagram link">
              <img src={instagramIcon} alt="instagram logo" />
            </StyledSocialLink> */}
          </StyledSocialLinksWrapper>
        </StyledSocialAndPolicyLinksWrapper>
      </StyledFooterContentWrapper>
      <StyledBottomFooterWrapper>
        <StyledBottomFooterContentWrapper>
          <StyledCopyright>
            &copy; Copyright 2022 Aurec Home. Wszelkie prawa zastrzezone.
          </StyledCopyright>
          <Credits>
            <span>Projekt i realizacja:</span>
            <CreditsLink
              href="https://www.landingpage.indigital.pl/"
              target="_blank"
              rel="nofollow"
            >
              <img src={indigitalIcon} alt="logo indigital" />
            </CreditsLink>
          </Credits>
        </StyledBottomFooterContentWrapper>
      </StyledBottomFooterWrapper>
    </StyledFooterWrapper>
  )
}

export default Footer
