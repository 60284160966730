type NavLink = {
  text: string
  link: `/${string}`

  active?: boolean
}

export const NAV_LINKS: NavLink[] = [
  {
    link: '/czym-jest-aurec/',
    text: 'Czym jest Aurec Active?',
  },
  {
    link: '/jak-korzystac/',
    text: 'Co możesz robić?',
  },
  // {
  //   link: '/co-nowego/',
  //   text: 'Co nowego?',
  // },
  {
    link: '/kim-jestesmy/',
    text: 'Kim jesteśmy?',
  },
]
