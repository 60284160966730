import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { NAV_LINKS } from 'constants/links'

import useLocation from 'hooks/useLocation'
import useScrollHide from 'hooks/useScrollHide'
import useScrollPosition from 'hooks/useScroll'

import media from 'styles/media'

import Logo from 'assets/icons/aurec-active-logo.svg'

import Hamburger from 'assets/icons/hamburger.svg'
import Close from 'assets/icons/close.svg'

import facebookIcon from 'assets/icons/facebook.svg'

type NavProps = {
  active?: boolean
  transparent?: boolean
}

const StyledNavigationWrapper = styled.nav<NavProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;

  transition: 250ms ease transform, 250ms ease background-color,
    250ms ease box-shadow;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  transform: translate3d(0, -101%, 0);

  ${({ transparent }) =>
    transparent === true &&
    css`
      background-color: transparent;
      box-shadow: none;
    `}

  ${({ active }) =>
    active === true &&
    css`
      transform: translate3d(0px, 0px, 0px);
    `}
`

const StyledNavigationContentWrapper = styled.div`
  max-width: 1400px;
  padding: 15px 20px 15px 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg.min} {
    padding: 15px 50px 15px 50px;
  }
`

const StyledLogoWrapper = styled(Link)<{ bigLogo?: boolean }>`
  line-height: 1;

  img {
    transition: 0.3s;
  }

  ${media.lg.min} {
    ${({ bigLogo }) =>
      bigLogo &&
      css`
        img {
          transform: scale(1.2);
        }
      `}
  }
`

const StyledNavigationLinksDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${media.lg.max} {
    display: none;
  }
`

const StyledNavigationLinkDesktop = styled(Link)<NavProps>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  /* margin: 0 18px 0 18px; */
  font-weight: 500;
  transition: 0.3s;
  position: relative;
  overflow: hidden;

  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.orange};
    transform: scaleX(0);
    transform-origin: 0;
    transition: 0.3s;
  }

  &:hover::before {
    transform: scaleX(1);
  }

  ${media.xl.min} {
    margin: 0 30px 0 30px;
    font-size: 18px;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.orange};
      &:before {
        transform: scaleX(1);
      }
    `}
`

const StyledDesktopSocialLinksWrapper = styled.div`
  display: flex;

  ${media.lg.max} {
    display: none;
  }
`

const StyledSocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  margin: 5px;
  line-height: initial;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`

const StyledNavigationMobileSidebar = styled.section<NavProps>`
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  top: 0;
  right: 0;
  z-index: 20;
  background: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    display: flex;
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    ${({ active }) =>
      active &&
      css`
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `}
  }
`

const StyledNavigationMobileHeader = styled.header`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledNavigationLinksMobile = styled.div`
  margin: 40px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledNavigationLinkMobile = styled(Link)<NavProps>`
  display: flex;
  font-weight: 500;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.lightorange : theme.colors.orange};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightorange : 'transparent'};
  /* height: 62px; */
  padding: 16px 0;
  border-radius: 34px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`

const StyledNavigationMobileFooter = styled.footer`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  p {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }

  a {
    margin-top: 10px;
  }
`

const StyledNavigationHamburgerButton = styled.button`
  display: none;
  padding: 0;
  background: none;
  border: none;

  ${media.lg.max} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
`

const StyledInvestmentLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 20px;
  line-height: normal;
  border-radius: 34px;
  transition: 0.3s;

  &.miasteczko-jutrzenki {
    background-color: #3ca742;
    color: #ffffff;
    border: 2px solid #3ca742;
  }
  &.fabrica-ursus {
    background-color: #fcaf17;
    color: #272727;
    border: 2px solid #fcaf17;
  }

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }
`

const Navigation: React.FC = () => {
  const [once, setOnce] = useState(true)
  const [active, setActive] = useState(false)
  const position = useScrollPosition(5)
  const isHidden = useScrollHide(5, 150)
  const pathname = useLocation()

  const isNavActive = !isHidden || active || once

  useEffect(() => {
    setOnce(false)
  }, [])

  return (
    <StyledNavigationWrapper
      active={isNavActive}
      id="navigation"
      transparent={position.y <= 100}
    >
      <StyledNavigationContentWrapper>
        <StyledLogoWrapper to="/" bigLogo={position.y === 0}>
          <img src={Logo} alt="Logo AurecActive" />
        </StyledLogoWrapper>

        <StyledNavigationLinksDesktop>
          {NAV_LINKS.map((el) => (
            <StyledNavigationLinkDesktop
              to={el.link}
              key={el.text}
              active={pathname.includes(el.link)}
            >
              {el.text}
            </StyledNavigationLinkDesktop>
          ))}
          <StyledInvestmentLink
            href="https://www.miasteczkojutrzenki.pl/"
            className="miasteczko-jutrzenki"
            target="_blank"
          >
            Miasteczko Jutrzenki
          </StyledInvestmentLink>
          <StyledInvestmentLink
            href="https://fabrica-ursus.pl/"
            className="fabrica-ursus"
            target="_blank"
          >
            Fabrica Ursus
          </StyledInvestmentLink>
        </StyledNavigationLinksDesktop>

        {/* <StyledDesktopSocialLinksWrapper>
          <StyledSocialLink
            href="https://www.facebook.com/AurecHome"
            aria-label="facebook link"
            target="_blank"
          >
            <img src={facebookIcon} alt="facebook logo" />
          </StyledSocialLink>
          <StyledSocialLink href="" aria-label="instagram link">
            <img src={instagramIcon} alt="instagram logo" />
          </StyledSocialLink>
        </StyledDesktopSocialLinksWrapper> */}

        <StyledNavigationMobileSidebar active={active}>
          <StyledNavigationMobileHeader>
            <StyledLogoWrapper to="/">
              <img src={Logo} alt="Logo Aurec Active" />
            </StyledLogoWrapper>
            <StyledCloseButton
              onClick={() => {
                setActive(false)
              }}
              aria-label="zamknij menu"
            >
              <img src={Close} alt="zamknij menu" />
            </StyledCloseButton>
          </StyledNavigationMobileHeader>
          <StyledNavigationLinksMobile>
            {NAV_LINKS.map((el) => {
              return (
                <StyledNavigationLinkMobile
                  to={el.link}
                  key={el.text}
                  active={pathname.includes(el.link)}
                >
                  {el.text}
                </StyledNavigationLinkMobile>
              )
            })}
            <StyledInvestmentLink
              href="https://www.miasteczkojutrzenki.pl/"
              className="miasteczko-jutrzenki"
            >
              Miasteczko Jutrzenki
            </StyledInvestmentLink>
            <StyledInvestmentLink
              href="https://fabrica-ursus.pl/"
              className="fabrica-ursus"
            >
              Fabrica Ursus
            </StyledInvestmentLink>
          </StyledNavigationLinksMobile>
          <StyledNavigationMobileFooter>
            <p>Sprawdź nas na:</p>
            <StyledSocialLink
              href="https://pl-pl.facebook.com/"
              aria-label="facebook link"
              target="_blank"
            >
              <img src={facebookIcon} alt="facebook logo" />
            </StyledSocialLink>
            {/* <StyledSocialLink
              href="https://www.instagram.com/"
              aria-label="instagram link"
            >
              <img src={instagramIcon} alt="instagram logo" />
            </StyledSocialLink> */}
          </StyledNavigationMobileFooter>
        </StyledNavigationMobileSidebar>

        <StyledNavigationHamburgerButton
          onClick={() => {
            setActive(true)
          }}
        >
          <img src={Hamburger} alt="ikona menu" />
        </StyledNavigationHamburgerButton>
      </StyledNavigationContentWrapper>
    </StyledNavigationWrapper>
  )
}

export default Navigation
