import Cookies from 'components/cookies'
import * as React from 'react'

import GlobalStyles from 'styles/global'
import Footer from './footer'
import Nav from './nav'

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Cookies />
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
